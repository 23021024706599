<template>
  <v-sheet :color="wsATTENTION">
    <div class="pa-4">

      <!-- Home-->
      <div v-if="navigation === 'home'">
        <div class="d-flex justify-space-between">
          <v-sheet color="transparent" dark class="d-flex align-center">
            <img src="/library/img/support/avatar.png"
                 style="border-radius: 8px"
                 width="42" height="42"
                 class="mr-4"
            >
            <h4 style="line-height: 1.2">{{ $t('support.header.hello') }} </h4>
          </v-sheet>
          <v-btn icon dark @click="$emit('close')">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </div>


        <v-hover #default="{hover}">
          <v-sheet
              @click="$emit('open-help')"
              class="mt-4 pa-3 d-flex justify-space-between align-center pointer"
              style="border-radius: 8px"
              v-ripple
              :style="hover ? `outline: 3px solid ${wsATTENTION + 66} !important` : ''"
          >
            <h5 class="wsDARKER">{{ $t('support.chat.start') }}</h5>
            <v-icon :color="wsATTENTION">mdi-send</v-icon>
          </v-sheet>
        </v-hover>




      </div>

      <!-- Navigation-->
      <div v-else class="d-flex justify-space-between align-center">
        <div class="d-flex align-center pr-2">
          <v-icon v-if="displayReturn" @click="$emit('return')" class="ml-n2"  color="#ffffff">mdi-chevron-left</v-icon>
          <h4 style="color : #ffffff" class="shorten-text">{{ navigationText }}</h4>
        </div>

        <div class="d-flex align-center">


          <ws-button
              v-if="navigationText === this.$t('Help')"
              @click="$store.state.support.displayArchive = !$store.state.support.displayArchive"
              class="mr-2" color="white" height="28" min-width="48" outlined
              :label="$store.state.support.displayArchive ? $t('support.chat.active_requests') : $t('support.chat.archive')  " />

          <ws-link  v-if="link" :href="link" new-window >
            <v-icon dark size="20">mdi-open-in-new</v-icon>
          </ws-link>

          <v-btn icon dark @click="$emit('close')">
            <v-icon>mdi-close</v-icon>
          </v-btn>

        </div>




      </div>


    </div>
  </v-sheet>
</template>

<script>
export default {
  name: "supportHeader",
  props : {
    navigation: {
      type : String
    },
    navigationText : {
      type : String
    },
    displayReturn : {
      type : Boolean
    },
    link: {
      type : String
    },
  }
}
</script>

<style scoped>

</style>